@font-face {
  font-family: "Causten", sans-serif;
  src: url(./webfonts/Causten-Thin.ttf) format("truetype");
  font-display: swap;
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "Causten", sans-serif;
  src: url(./webfonts/Causten-Thin-Oblique.ttf) format("truetype");
  font-display: swap;
  font-style: oblique;
  font-weight: 100;
}
@font-face {
  font-family: "Causten", sans-serif;
  src: url(./webfonts/Causten-Extra-Light.ttf) format("truetype");
  font-display: swap;
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Causten", sans-serif;
  src: url(./webfonts/Causten-Extra-Light-Oblique.ttf) format("truetype");
  font-display: swap;
  font-style: oblique;
  font-weight: 200;
}
@font-face {
  font-family: "Causten", sans-serif;
  src: url(./webfonts/Causten-Light.ttf) format("truetype");
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Causten", sans-serif;
  src: url(./webfonts/Causten-Light-Oblique.ttf) format("truetype");
  font-display: swap;
  font-style: oblique;
  font-weight: 300;
}
@font-face {
  font-family: "Causten", sans-serif;
  src: url(./webfonts/Causten-Regular.ttf) format("truetype");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Causten", sans-serif;
  src: url(./webfonts/Causten-Regular-Oblique.ttf) format("truetype");
  font-display: swap;
  font-style: oblique;
  font-weight: 400;
}
@font-face {
  font-family: "Causten", sans-serif;
  src: url(./webfonts/Causten-Medium.ttf) format("truetype");
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Causten", sans-serif;
  src: url(./webfonts/Causten-Medium-Oblique.ttf) format("truetype");
  font-display: swap;
  font-style: oblique;
  font-weight: 500;
}
@font-face {
  font-family: "Causten", sans-serif;
  src: url(./webfonts/Causten-Semi-Bold.ttf) format("truetype");
  font-display: swap;
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Causten", sans-serif;
  src: url(./webfonts/Causten-Semi-Bold-Oblique.ttf) format("truetype");
  font-display: swap;
  font-style: oblique;
  font-weight: 600;
}
@font-face {
  font-family: "Causten", sans-serif;
  src: url(./webfonts/Causten-Bold.ttf) format("truetype");
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Causten", sans-serif;
  src: url(./webfonts/Causten-Bold-Oblique.ttf) format("truetype");
  font-display: swap;
  font-style: oblique;
  font-weight: 700;
}
@font-face {
  font-family: "Causten", sans-serif;
  src: url(./webfonts/Causten-Extra-Bold.ttf) format("truetype");
  font-display: swap;
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: "Causten", sans-serif;
  src: url(./webfonts/Causten-Extra-Bold-Oblique.ttf) format("truetype");
  font-display: swap;
  font-style: oblique;
  font-weight: 800;
}
@font-face {
  font-family: "Causten", sans-serif;
  src: url(./webfonts/Causten-Black.ttf) format("truetype");
  font-display: swap;
  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: "Causten", sans-serif;
  src: url(./webfonts/Causten-Black-Oblique.ttf) format("truetype");
  font-display: swap;
  font-style: oblique;
  font-weight: 900;
}
