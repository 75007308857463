@import "../../../assets/variables";

.contact-container {
  background-image: url(../../../assets/images/contact-bg.svg);
  background-repeat: repeat-x;
  background-position: center 40%;
  position: relative;

  iframe {
    max-width: 100%;
    border-radius: 16px 16px 0px 0px;
    margin-bottom: 2rem;
    border: 0;
    width: 100%;
  }

  @media (max-width: 767px) {
    iframe {
      border-radius: 0px;
      margin-bottom: 0px;
      margin-top: 1rem;
    }
  }

  .moving-object {
    right: 0px;
    z-index: 0;
    bottom: 0px;

    img {
      height: 400px;
    }
  }

  .form-container {
    background-color: $color-white;
    position: relative;
    z-index: 1;
    padding: 16px;
    border-radius: 16px;
    box-shadow: 0px 0px 80px 0px rgba(205, 205, 205, 0.25);

    .footer-social ul {
      justify-content: flex-start;
      margin-bottom: 2rem;

      svg {
        width: 49px;
        height: 49px;
      }
    }
  }

  @media (max-width: 767px) {
    .social {
      display: block !important;
      margin-bottom: 1rem;

      ul {
        justify-content: flex-start;

        svg {
          height: 28px;
        }
      }
    }
  }

  .contact-list {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    padding-left: 1rem;

    li {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 10px;

      button {
        display: inline;
        border: none;
        padding: 0px;
        background-color: transparent;
        color: #4f4f4f;

        .fa {
          font-size: 12px;
        }
      }

      svg {
        width: 40px;
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 1023px) and (min-width: 768px) {
  .contact-container {
    .contact-list {
      padding-left: 0px;
      margin-bottom: 2rem;
    }
  }
}

@media (max-width: 767px) {
  .contact-container {
    background: none;
    .moving-object {
      display: none;
    }
    .form-container {
      background-color: transparent;
      box-shadow: none;
      padding: 0px;
    }
    .mobile-map {
      background-image: url(../../../assets/images/contact-bg.svg);
      background-repeat: repeat-x;
      background-position: center center;
      background-size: cover;
    }
    .mobile-contact {
      padding-top: 2rem;
      padding-bottom: 2rem;
      position: relative;
      overflow-x: clip;
      .contact-list {
        padding-left: 0px;
        margin-bottom: 2rem;
      }
      .moving-object {
        bottom: 100px;
        right: -80px;
        display: block;
        img {
          height: 300px;
        }
      }
    }
  }
}
