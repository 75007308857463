/*colors*/
$color-black: #000000;
$color-white: #ffffff;
$color-gold: #b27d52;
$color-blue: #446073;
$color-running-text: #494f59;
$color-green: #005d76;
$color-body: #4f4f4f;
$color-light-gray: #fafafa;
$color-whatsapp: #25d366;
$color-whatsapp-dark: #075e54;

/*fonts*/
$body-font: "Causten", sans-serif;
$title-font: "Adso", sans-serif;

/*speed*/
$speed-rotate: 100s;

/*function*/
@mixin transition {
  transition: all 0.5s;
}

@mixin center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin unstyle-list {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

/*animation*/
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@mixin rotating {
  -webkit-animation: rotating $speed-rotate linear infinite;
  -moz-animation: rotating $speed-rotate linear infinite;
  -ms-animation: rotating $speed-rotate linear infinite;
  -o-animation: rotating $speed-rotate linear infinite;
  animation: rotating $speed-rotate linear infinite;
}
