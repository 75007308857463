@import "../../../../assets/variables";

.social {
  ul {
    @include center-flex;
    @include unstyle-list;

    li {
      margin: 0px 9px;

      a {
        @include transition;
        display: block;

        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
}

.social-mobile {
  display: none;
}

@media (max-width: 767px) {
  .social-mobile {
    display: block;
    padding-top: 50px;
    margin-left: 19px;

    ul {
      @include unstyle-list;
      display: flex;
      li {
        margin: 0px 12px 0px 0px;
        a {
          display: block;

          svg {
            filter: invert(54%) sepia(14%) saturate(1310%) hue-rotate(345deg)
              brightness(94%) contrast(90%);
            height: 20px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .social {
    display: none;
  }
}
