@import "../../../assets/variables";

.delivery-apps-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .delivery-app-item {
    margin-bottom: 1rem;
    width: 100%;

    a {
      @include transition;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      text-decoration: none;

      img {
        height: 75px;
      }

      .delivery-button {
        @include transition;
        display: block;
        padding: 16px 48px;
        border-radius: 127px;
        background-color: $color-blue;
        color: $color-white;
        text-decoration: none;
        font-family: $body-font;
        font-weight: 700;
        line-height: 26px;
      }

      &:hover {
        background-color: $color-light-gray;
        text-decoration: none;

        .delivery-button {
          background-color: $color-gold;
          text-decoration: none;
        }
      }
    }
  }
}
