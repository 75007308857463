@import "../../../assets/variables";

header {
  position: relative;
  z-index: 10;

  .header-container {
    position: relative;
  }
}

nav.navbar {
  padding-top: 56px;

  &.sticky-top {
    position: fixed;
    top: 0;
    z-index: 1024;
    width: 100%;
    background-color: $color-white;
    box-shadow: 0px 0px 10px $color-blue;
    padding-top: 25px !important;
    padding-bottom: 25px;

    .header-border {
      display: none;
    }
  }
}

.navbar-brand {
  margin-right: 51px;
}

@media (max-width: 767px) {
  nav.navbar {
    padding-top: 18px;
  }
  .navbar-brand {
    margin-right: auto !important;
    margin-left: auto;
  }
  .header-container {
    justify-content: center;
    width: 100%;
  }
}

.nav-link {
  @include transition;
  font-family: $title-font;
  font-size: 20px !important;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  margin-left: 19px !important;
  margin-right: 19px !important;
  color: $color-running-text !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  border-bottom: 2px solid transparent !important;
  padding-bottom: 2px !important;

  &:hover {
    color: $color-blue !important;
    border-bottom: 2px solid $color-gold !important;
  }

  &.active {
    font-weight: bold;
    border-bottom: 2px solid $color-gold !important;
  }
}

@media (max-width: 767px) {
  .nav-link {
    color: $color-white !important;
    font-weight: 600;

    &:hover {
      color: $color-gold !important;
      border-bottom-color: transparent !important;
    }

    &.active-scroll-spy {
      color: $color-gold !important;
      border-bottom-color: transparent !important;
    }
  }
}

header .container {
  position: relative;

  .header-border {
    flex: 1;
    height: 30px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      background-color: #b27d52;
      height: 0.75px;
      width: 95%;
      z-index: -2;
      transform: translateY(-50%);
      top: 50%;
    }
  }

  .bg-fix {
    position: relative;
  }

  @media (max-width: 767px) {
    .header-border {
      display: none;
    }
  }
}

.navbar-toggler {
  border-color: transparent !important;
  color: $color-blue !important;
  font-size: 30px !important;
  position: absolute;
  left: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

@media (max-width: 1024px) and (min-width: 768px) {
  .navbar-toggler {
    position: static;
  }
}

@media (max-width: 767px) {
  .offcanvas-header {
    background-color: $color-white;
  }
  .offcanvas-body {
    background-color: $color-green;
    background-image: url(../../../assets/images/vertical-decoration.png);
    background-repeat: repeat-y;
    background-position: top right;
  }
}
