@import "../../../assets/variables";

.gallery-container {
  position: relative;
  overflow-x: clip;

  .row {
    align-items: center;

    .gallery-images {
      position: relative;
      display: grid;
      grid-template-areas: "item item";

      .item {
        grid-area: "item";

        &:last-child {
          position: relative;
          padding-top: 20%;
          left: -15%;
        }

        img {
          max-width: 100%;
          border-radius: 6px;
        }
      }

      @media (max-width: 767px) {
        .item {
          padding-left: 7px;
          padding-right: 7px;
          padding-top: 25%;

          &:last-child {
            position: static;
            padding-top: 0px;
          }
        }
      }
    }

    @media (max-width: 767px) {
      .gallery-images {
        margin-bottom: 4rem;
      }
    }

    .btns-container {
      margin-top: 4rem;

      .button {
        &:first-child {
          margin-right: 10px;
        }

        &:last-child {
          margin-left: 10px;
        }
      }
    }

    @media (max-width: 767px) {
      p {
        padding-right: 7rem;
      }
    }
  }

  .moving-object {
    &.element-1 {
      right: -200px;
      bottom: -100px;

      img {
        height: 500px;
      }
    }

    &.element-2 {
      left: 0px;
      bottom: 0px;

      img {
        height: 500px;
      }
    }

    @media (max-width: 1439px) and (min-width: 1200px) {
      &.element-2 {
        img {
          height: 400px;
        }
      }

      &.element-1 {
        right: -200px;
        img {
          height: 400px;
        }
      }
    }

    @media (max-width: 1199px) and (min-width: 1024px) {
      &.element-2 {
        display: none;
      }

      &.element-1 {
        right: -150px;
        img {
          height: 300px;
        }
      }
    }

    @media (max-width: 1023px) and (min-width: 768px) {
      &.element-2 {
        display: none;
      }

      &.element-1 {
        right: -150px;
        bottom: 0px;
        img {
          height: 300px;
        }
      }
    }

    @media (max-width: 767px) {
      &.element-2 {
        display: none;
      }

      &.element-1 {
        bottom: 0px;
        right: -60px;

        img {
          height: 148px;
        }
      }
    }
  }
}
