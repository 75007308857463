@import "../../../assets/variables";

footer {
  margin-top: 4rem;
  font-size: 16px;
  line-height: 24px;
  color: $color-white;
  background-color: $color-blue;
  padding-top: 1rem;
  padding-bottom: 1rem;

  p {
    margin: 0px;
  }

  .social {
    ul {
      justify-content: flex-end;

      svg {
        filter: invert(100%) sepia(76%) saturate(2%) hue-rotate(17deg)
          brightness(104%) contrast(101%);
      }
    }
  }

  @media (max-width: 767px) {
    p {
      margin-bottom: 1rem;
    }

    .social {
      display: block;
    }
  }
}
