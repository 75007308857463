@import "../../../assets/variables";

.main-banner {
  overflow-x: clip;
  padding-top: 40px;
  position: relative;

  .container {
    position: relative;

    .main-banner-row {
      align-items: center;
    }

    @media (max-width: 767px) {
      .main-banner-row {
        align-items: start;
      }
    }

    .moving-object {
      bottom: 0px;
      left: -100px;

      img {
        height: 470px;
      }
    }

    @media (max-width: 1023px) and (min-width: 768px) {
      .moving-object {
        bottom: 100px;
        left: 0px;

        img {
          height: 300px;
          transform: scaleX(-1);
        }
      }
    }

    @media (max-width: 767px) {
      .moving-object {
        right: 0px;
        bottom: 50px;
        left: unset;

        img {
          height: 120px;
          transform: scaleX(-1);
        }
      }
    }

    .caption-container {
      padding-left: 4rem;
      margin-top: -70px;

      p {
        margin-bottom: 57px;
      }
    }

    @media (max-width: 1200px) {
      .caption-container {
        padding-left: 0px;
      }
    }

    @media (max-width: 1199px) and (min-width: 1024px) {
      .caption-container {
        padding-left: 0px;
      }
    }

    @media (max-width: 1023px) and (min-width: 768px) {
      .caption-container {
        padding-left: 0px;
      }
    }

    @media (max-width: 767px) {
      .caption-container {
        padding-left: 0px;
        margin-top: 0px;

        p {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }

    .image-container {
      position: relative;
      top: -70px;
      margin-bottom: -70px;

      svg {
        @include rotating;
        height: 950px;
        top: 0px;
        position: absolute;
        z-index: 0;
      }

      .swiper {
        overflow: visible;

        .swiper-slide {
          @include transition;
          opacity: 0 !important;

          &.swiper-slide-visible {
            opacity: 1 !important;
          }
        }
      }

      img {
        z-index: 1;
        position: relative;
        top: 0px;
      }
    }
    @media (max-width: 767px) {
      .image-container {
        top: 0px;
        margin-bottom: 0px;
        left: -20px;

        svg {
          height: 250px;
          width: 250px;
        }
        img {
          height: 250px;
        }
      }
    }

    .scroll-down {
      position: absolute;
      bottom: 288px;
      left: 50%;
      transform: translateX(-50px);
      font-family: $body-font;
      color: $color-blue;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      padding: 0px 15px;

      &:after {
        content: "";
        position: absolute;
        width: 1px;
        height: 60px;
        background-color: $color-blue;
        left: 50%;
        transform: translateX(-50%);
        top: 34px;
      }
    }

    @media (max-width: 767px) {
      .scroll-down {
        display: none;
      }
    }
  }
}
