/*container width*/
$container-max-width: (
  xs: 100%,
  sm: 540px,
  md: 520px,
  lg: 760px,
  xl: 940px,
  xxl: 1120px,
) !default;

.container {
  width: 100%;
}

@media (min-width: 576px) {
  .container {
    width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 620px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 860px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1040px;
  }
}

@media (min-width: 1400px) {
  .container {
    width: 1220px;
  }
}
