@import "../../../assets/variables";

.separator-item {
  display: flex;
  justify-content: center;
  padding-bottom: 4rem;
  padding-top: 4rem;
  position: relative;

  @media (max-width: 767px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    height: 0.75px;
    width: 50%;
    background-color: $color-gold;
    top: 50%;
    transform: translateY(-50%);
  }

  span {
    display: inline-block;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background-color: $color-white;

    svg {
      height: 30px;
    }
  }
}
