@import "../../../assets/variables";

.food-menu-container {
  margin-top: 4rem;
  position: relative;

  .menu-list-container {
    margin-top: 50px;
    margin-bottom: 50px;

    ul {
      padding: 0px;
      margin: 0px;
      display: flex;
      justify-content: center;
      list-style-type: none;

      li {
        display: block;
        margin-left: 30px;
        margin-right: 30px;
        .icon-container {
          margin-bottom: 10px;
          text-align: center;

          svg {
            height: 66px;
          }
        }
        h4 {
          font-family: $body-font;
          font-weight: 400;
          font-size: 20px;
          line-height: 28px;
          color: $color-gold;
          text-align: center;
          text-wrap: nowrap;
        }
      }
    }

    @media (max-width: 1439px) and (min-width: 1200px) {
      ul {
        li {
          h4 {
            font-size: 16px;
          }
        }
      }
    }

    @media (max-width: 1199px) and (min-width: 1024px) {
      ul {
        flex-wrap: wrap;

        li {
          width: 15%;
          margin-bottom: 30px;
        }
      }
    }

    @media (max-width: 1023px) and (min-width: 768px) {
      ul {
        flex-wrap: wrap;
        li {
          width: 25%;
          margin-bottom: 30px;
          margin-left: 0px;
          margin-right: 0px;
        }
      }
    }

    @media (max-width: 767px) {
      ul {
        flex-wrap: wrap;

        li {
          width: 50%;
          margin-bottom: 30px;
          margin-left: 0px;
          margin-right: 0px;

          .icon-container {
            svg {
              height: 62px;
            }
          }

          h4 {
            font-size: 12px;
          }
        }
      }
    }
  }

  .moving-object {
    right: 0px;
    bottom: -100px;
    img {
      height: 400px;
    }
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    .moving-object {
      bottom: 0px;
      img {
        height: 200px;
      }
    }
  }

  @media (max-width: 767px) {
    .moving-object {
      right: unset;
      left: 0px;
      bottom: 0px;
      img {
        height: 105px;
        transform: scaleX(-1);
      }
    }
  }
}
