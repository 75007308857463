@import "../../../assets/variables";

.about-container {
  position: relative;

  .container {
    position: relative;

    .moving-object {
      right: 0px;
      bottom: -100px;
      z-index: 1;

      img {
        height: 560px;
      }
    }

    @media (max-width: 1199px) and (min-width: 768px) {
      .moving-object {
        bottom: -300px;
        img {
          height: 300px;
        }
      }
    }

    @media (max-width: 767px) {
      .moving-object {
        bottom: -100px;
        z-index: 1;
        img {
          height: 122px;
        }
      }
    }
  }

  .about-content {
    padding-top: 100px;
  }

  @media (max-width: 767px) {
    .about-content {
      padding-top: 20px;
    }
  }

  .wide-separator {
    .moving-object {
      right: unset;
      left: -100px;
      bottom: 0px;

      svg {
        height: 700px;
        width: 700px;
      }
    }
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    .wide-separator {
      .moving-object {
        bottom: -50px;
        left: 0px;
        svg {
          height: 300px;
          width: 300px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .wide-separator {
      .moving-object {
        left: 0px;
        bottom: -50px;

        svg {
          height: 100px;
          width: 100px;
        }
      }
    }
  }

  .about-images {
    display: grid;
    column-gap: 20px;
    row-gap: 20px;
    grid-template-areas: "large-item item1" "large-item item2";

    .large-item {
      grid-area: large-item;
      padding-top: 100px;
    }

    .item {
      grid-area: item1;

      &:last-child {
        grid-area: item2;
      }
    }

    img {
      max-width: 100%;
    }
  }

  @media (max-width: 1023px) and (min-width: 768px) {
    .about-images {
      display: flex;

      .large-item {
        padding-top: 0px;
      }
    }
  }

  @media (max-width: 767px) {
    .about-images {
      display: flex;

      .large-item {
        padding-top: 0px;
      }
    }
  }
}

@media (max-width: 767px) {
  .about-container {
    margin-bottom: 4rem;
  }
}
