@import "../../../assets/variables";

.subheading {
  position: relative;
  color: $color-gold;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  padding-left: 27px;
  padding-right: 27px;
  display: inline-block;
  width: auto;
  font-family: $body-font;

  &:after {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: $color-gold;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    z-index: -1;
  }

  span {
    display: inline-block;
    background-color: $color-white;
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media (max-width: 767px) {
  .subheading {
    font-size: 14px;
    line-height: 18px;
    padding-left: 20px;
    padding-right: 20px;

    &:after {
      height: 0.83px;
    }

    span {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
