@import "./variables";
@import "./fonts.scss";

html {
  scroll-behavior: smooth;
}

body {
  font-family: $body-font;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $color-body;

  p {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 767px) {
    p {
      font-size: 14px;
      line-height: 17px;
    }
  }
}

.app-sticky {
  padding-top: 90px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $title-font;
  color: $color-blue;
  font-weight: 700;
}
