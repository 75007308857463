@import "../../../assets/variables";

.wide-separator {
  height: 353px;
  background-image: url(../../../assets/images/sep-bg.svg);
  background-repeat: repeat-x;
  background-position: center center;
  position: relative;
  background-size: cover;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

@media (max-width: 1199px) and (min-width: 768px) {
  .wide-separator {
    height: 200px;
  }
}

@media (max-width: 767px) {
  .wide-separator {
    height: 60px;
  }
}
