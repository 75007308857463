@import "../../../assets/variables";

.button {
  @include transition;
  padding: 16px 48px;
  color: $color-white;
  background-color: $color-blue;
  border: 1px solid transparent;
  border-radius: 127px;
  font-family: $body-font;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  display: inline-block;
  text-align: center;
  text-decoration: none;

  &.alt-button {
    background-color: $color-gold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;

    svg {
      margin-right: 10px;
    }

    &:hover {
      background-color: $color-blue;
      text-decoration: none;
    }
  }

  &:hover {
    background-color: $color-gold;
    text-decoration: none;
  }

  &:focus,
  &:active {
    background-color: $color-body;
  }
}

@media (max-width: 767px) {
  .button {
    font-size: 14px;
    line-height: 21px;
    padding: 10px 15px;

    &.alt-button {
      padding-top: 5px;
      padding-bottom: 5px;
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
}
