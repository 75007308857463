@import "../../../assets/variables";

h1.title,
h2.title {
  font-size: 60px;
  line-height: 49px;
  text-transform: uppercase;
  margin-bottom: 32px;

  &.with-decoration {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 39px;

    svg {
      margin-left: 1rem;
    }
  }
}

@media (max-width: 1023px) and (min-width: 768px) {
  h1.title,
  h2.title {
    font-size: 50px;
  }
}

@media (max-width: 767px) {
  h1.title,
  h2.title {
    font-size: 30px;
    line-height: 27px;

    &.with-decoration {
      svg {
        height: 50px;
      }
    }
  }
}
